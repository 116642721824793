import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/addFav";
import adminService from "../../admin/js/admin-service";
import CommonDialog from "@/olp-components/common/common-dialog.vue";
import { bus } from "@/main";
import utility from "../../../shared/utility";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      backSlash: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      products: [],
      productList: [],
      cartList: [],
      businessList: [],
      search: "",
      cartId: "",
      showDialog: false,
      showSearch: false,
      message: "",
      product: "",
      selectedBusiness: "",
      disableBusiness: false,
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getBusinessList();
    bus.$on("refreshProduct",(data)=>{
      this.cartList=[]
      let partObj = {
        user_id: this.userId,
        SearchStr: "",
        class_keys: [],
        bu_key: this.selectedBusiness,
      };
      data?this.getProductList(partObj):""
      
    })
  },
  mounted() {},
  methods: {
    async getBusinessList() {
      let response = await adminService.getUserListBusiness("get", this.userId);
      this.businessList = response.BusinessList;
      if (EncryptUtility.localStorageDecrypt("olpBusinessSelected")) {
        this.selectedBusiness = JSON.parse(EncryptUtility.localStorageDecrypt("olpBusinessSelected"));
        this.onChangeBusiness();
      }
      if( this.businessList?.length==1)
      {
        this.selectedBusiness=this.businessList[0].bu_id
        this.onChangeBusiness()
      }
    },
    async onChangeBusiness() {
      this.disableBusiness = true;
      this.showSearch = true;
      let partObj = {
        user_id: this.userId,
        SearchStr: "",
        class_keys: [],
        bu_key: this.selectedBusiness,
      };
      this.getProductList(partObj);
      EncryptUtility.localStorageEncrypt( "olpBusinessSelected",String(this.selectedBusiness));
      this.refreshCartList()
    },
    clearSearch() {
      bus.$emit("cart", "");
      this.products = [];
      this.selectedBusiness = "";
      EncryptUtility.localStorageEncrypt("olpBusinessSelected", "");
      this.search = "";
      this.disableBusiness = false;
    },
    addFav() { 
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    actionSetter(action) {
      return Utility.actionSetter(this.actionList, action);
    },
    async refreshCartList() {
      let partObj = {
        user_id: this.userId,
        SearchStr: "",
        class_keys: [],
        bu_key: this.selectedBusiness,
      };
      this.getProductList(partObj);
      let resp = await adminService.getCartList("get",this.userId,this.selectedBusiness,false)
      if (resp != "NA") {
        this.cartList = resp?.map((x) => ({
          cart_id:x.cart_id,
          part_id: x.part_key,
          Part_Number: x.part_num,
          img: x.img,
          Description: x.part_desc,
          qty: x.qty,
          available_qty: x.available_qty,
          cart_line_key: x.cart_line_id,
        }));
        bus.$emit("cart", this.cartList);
        resp[0]?.cart_id
          ? (this.cartId = resp[0]?.cart_id)
          : (this.cartId = "");
      } else {
        bus.$emit("cart", "");
        this.cartList = [];
      }
    },
    async updateCart(product, passType) {
      const existingCartItem = this.cartList.find(
        (cartItem) => cartItem.part_id === product.part_id
      );
      let cartlines = [
        {
          cart_line_key: existingCartItem ? existingCartItem.cart_line_key : 0,
          part_key: product.part_id,
          qty: product.qty,
        },
      ];

      let updateObj = {
        user_id: this.userId,
        pass: passType,
        cart_key: this.cartId,
        cartlines: cartlines,
        bu_key: this.selectedBusiness,
      };
      let submitObj = { json: JSON.stringify(updateObj) };
      let res;
      if (passType === 1) {
        res = await adminService.postCartUpdate("post", submitObj, true);
      } else {
        res = await adminService.postCartUpdate("post", submitObj, true);
      }
      if (res) {
        await this.refreshCartList();
      }
    },

    async modifyCart(product, actionType) {
      this.product = product;
      const existingCartItem = this.cartList.find(
        (cartItem) => cartItem.part_id === product.part_id
      );

      if (actionType === "increase") {
        this.products[product.index].qty++;
        if (this.cartId && product.added_in_cart) {
          if (existingCartItem) {
            await this.updateCart(this.products[product.index], 2); // Update existing item (pass=2)
          } else {
            await this.updateCart(this.products[product.index], 1); // Add new item (pass=1)
          }
        }
      } else if (actionType === "decrease") {
        if (this.products[product.index].qty > 0) {
          if (this.cartId && product.added_in_cart) {
            if (this.products[product.index].qty === 1) {
              this.showDialog = true;
              this.message =
                "Are you sure you want to remove this item from Cart?";
            } else {
              this.products[product.index].qty--;
              await this.updateCart(this.products[product.index], 2); // Update existing item (pass=2)
            }
          } else {
            this.products[product.index].qty--;
          }
        }
      }
    },

    async addToCart(product, index) {
      if (product.qty != 0) {
        const existingCartItem = this.cartList.find(
          (cartItem) => cartItem.part_id === product.part_id
        );
        if (this.cartList?.length == 0 && !existingCartItem) {
          let cartlines = [
            {
              cart_line_key: 0,
              part_key: product.part_id,
              qty: product.qty,
            },
          ];

          let updateObj = {
            user_id: this.userId,
            pass: 1,
            cart_key: this.cartId,
            cartlines: cartlines,
            bu_key: this.selectedBusiness,
          };

          let submitObj = { json: JSON.stringify(updateObj) };
          let res = await adminService.postPlaceOrder("post", submitObj, true);

          if (res?.Message) {
            await this.refreshCartList();
            this.showAddToCart = false;
          }
        } else {
          await this.updateCart(this.products[index], 1);
        }
      } else {
        utility.showNoDataToast("Add at least one quantity");
      }
    },
    // Replace increaseQuantity and decreaseQuantity with the new modifyCart function
    async increaseQuantity(product, index) {
      await this.modifyCart({ ...product, index }, "increase");
    },

    async decreaseQuantity(product, index) {
      await this.modifyCart({ ...product, index }, "decrease");
    },
    searchProduct(text) {
      this.search = text?.toUpperCase();
      if (text?.length > 2) {
        let partObj = {
          user_id: this.userId,
          SearchStr: text,
          class_keys: [],
          bu_key: this.selectedBusiness,
        };
        Utility.debounce(() => {
          this.getProductList(partObj);
        }, 700)();
      } else {
        let partObj = {
          user_id: this.userId,
          SearchStr: "",
          class_keys: [],
          bu_key: this.selectedBusiness,
        };
        this.getProductList(partObj);
      }
    },
    async getProductList(obj) {
      let submitObj = { json: JSON.stringify(obj) };
      let searchResult = await adminService.postProductList("post", submitObj, false );
      if (searchResult?.length > 0) {
        searchResult = searchResult?.map((v) => ({
          ...v,
          img: "https://t4.ftcdn.net/jpg/01/27/53/71/360_F_127537153_k1y9h4hUoAmbNXs6QlgWkCBd87qWwp2X.jpg",
        }));
        this.products = searchResult;
      } else {
        this.products = [];
      }
    },

    async onClickDialogOk() {
      this.products[this.product.index].qty--;
      let res = await this.updateCart(this.products[this.product.index], 3);
      await this.refreshCartList();
      if (!res) {
        this.showDialog = false;
        this.message = "";
      }
    },

    onClickDialogCancel() {
      this.showDialog = false;
      this.message = "";
    },
  },
  components: {
    breadcrumbComp,
    CommonDialog,
  },
};
